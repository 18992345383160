import React from "react";

const TechCard = ({ src, alt }) => {
  return (
    <div
      className="border rounded d-flex align-items-center p-3 mx-auto my-4 sdw-glow"
      style={{ width: "300px" }}
    >
      <img src={src} alt={alt} className="mr-auto" style={{ width: "50px" }} />
      <h4 className="text-muted mx-auto">{alt}</h4>
    </div>
  );
};

export default TechCard;
