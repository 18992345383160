import React from "react";
import Sun from "../images/sun.svg";

const Title = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="jumbotron text-center py-5">
          <img src={Sun} alt="" className="rotate" style={{ width: "50px" }} />
          <h1 className="title-text">ABHINN KRISHN</h1>
        </div>
      </div>
    </div>
  );
};

export default Title;
