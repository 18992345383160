import React from "react";
import { BrowserRouter } from "react-router-dom";

import Title from "./components/Title";
import Heading from "./components/Heading";
import About from "./components/About";
import TechStack from "./components/TechStack";
import ToolStack from "./components/ToolStack";
import Project from "./components/Project";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>
      <Title />
      <Heading />
      <About />
      <TechStack />
      <ToolStack />
      <Project />
      <Contact />
      <Footer  />
    </BrowserRouter>
  );
}

export default App;
