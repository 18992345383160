import React from "react";
import TechCard from "./Cards/TechCard";
import Git from "../images/git.svg";
import VScode from "../images/visualstudiocode.svg";
import Netlify from "../images/netlify.svg";
import Firebase from "../images/firebase.svg";

const ToolStack = () => {
  return (
    <div className="container-xxl div-divider">
      <div className="my-5 d-flex row">
        <div className="jumbotron py-3">
          <h1>My Tool Stack</h1>
        </div>
        <TechCard src={Git} alt="./git" />
        <TechCard src={Netlify} alt="Netlify" />
        <TechCard src={Firebase} alt="Firebase" />
        <TechCard src={VScode} alt="VS Code" />
      </div>
    </div>
  );
};

export default ToolStack;
