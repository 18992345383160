import React from "react";
import "../css/Hover.css";
import Github from "../images/github.svg";
import Linkedin from "../images/linkedin.svg";
import Insta from "../images/instagram.svg";

const Contact = () => {
  return (
    <div className=" div-divider">
      <div className="container-xxl mt-5 pb-4">
        <div className="jumbotron py-3">
          <h1>Want to Work Together?</h1>
        </div>
        <h1 className="display-3 text-center">
          <span style={{ color: "#AE45FF" }}>abhinnk21</span>@gmail.com
        </h1>
        <div className="text-center mt-5 mb-3">
          <a href="https://github.com/pikachuonacid/" className="mx-3 hvr-grow">
            <img src={Github} style={{ width: "35px" }} />
          </a>
          <a
            href="https://www.linkedin.com/in/abhinnkrishn/"
            className="mx-3 hvr-grow"
          >
            <img src={Linkedin} style={{ width: "35px" }} />
          </a>
          <a
            href="https://www.instagram.com/abhinnxo/"
            className="mx-3 hvr-grow"
          >
            <img src={Insta} style={{ width: "35px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
