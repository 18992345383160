import React from "react";
import Github from "../../images/github.svg";
import Globe from "../../images/globe.svg";

const ProjectCard = ({
  src1,
  src2,
  alt1,
  alt2,
  title,
  description,
  link1,
  link2,
}) => {
  return (
    <div
      className="border rounded align-items-center p-3 mx-3 my-4 hvr-float"
      style={{ width: "350px" }}
    >
      <h3 className="text-center title-text1">{title}</h3>
      <div className="text-center mt-3">
        <img src={src1} alt={alt1} style={{ width: "30px" }} />
        <img src={src2} alt={alt2} style={{ width: "30px" }} />
      </div>
      <p className="text-center px-3 mt-3">{description}</p>
      <div className="text-end">
        <a href={link1}>
          <img
            src={Github}
            className="hvr-grow"
            alt={alt1}
            style={{ width: "30px" }}
          />
        </a>
        &nbsp; &nbsp;
        <a href={link2}>
          <img
            src={Globe}
            className="hvr-grow"
            alt={alt2}
            style={{ width: "30px" }}
          />
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
