import React from "react";
import ProjectCard from "./Cards/ProjectCard";
import ReactJS from "../images/react.svg";
import Firebase from "../images/firebase.svg";
import JavaScript from "../images/javascript.svg";
import Java from "../images/java.svg";
import Bootstrap from "../images/bootstrap.svg";

const Project = () => {
  return (
    <div className="container-xxl div-divider mt-5 pb-5">
      <div className="jumbotron py-3">
        <h1>My Projects</h1>
      </div>
      <ProjectCard
        className="d-flex"
        src1={ReactJS}
        src2={Firebase}
        title="The Dukaandar Store"
        description="The Dukaandar is an Online e-commerce store which provides you with a wide range of products from clothing, cosmetics to electronics."
        link1="https://github.com/pikachuonacid/thedukaandar"
        link2="https://thedukaandar.netlify.app/"
      />
      <ProjectCard
        className="d-flex"
        src1={ReactJS}
        src2={Firebase}
        title="Chat Room"
        description="Chat Room is a realtime public group chat app made with ReactJS and Firebase. It also has a google auth for user authentication and to avoid any spam attack."
        link1="https://github.com/pikachuonacid/chat-room"
        link2="https://react-chat-app-21.web.app/"
      />
      <ProjectCard
        className="d-flex"
        src1={JavaScript}
        title="Astrominia"
        description="Astrominia is a Chorme extension which works on any chromium based browser, Beautiful Images from NASA and Quotes on Every New Tab You Open."
        link1="https://github.com/pikachuonacid/Astrominia-Chrome-Extension"
        link2="https://chrome.google.com/webstore/detail/new-tab-by-astrominia/ophifjbfjhcmphoigfhmbmcbemlmbgej/"
      />
      <ProjectCard
        className="d-flex"
        src1={Java}
        title="Roll A Dice"
        description="Roll A Dice is an Android app with over 3000+ downloads on Google playstore. 1000+ installs were reached only in 6 months after publishing for the first time."
        link1="https://github.com/pikachuonacid/Roll-a-Dice."
        link2="https://play.google.com/store/apps/details?id=com.abhinnkrishn.honey1.mysnakes/"
      />
      <ProjectCard
        src1={ReactJS}
        src2={Bootstrap}
        title="Abhinn Krishn"
        description="My Portfolio Website, made in React with <3."
        link1="https://github.com/pikachuonacid/Portfoilo"
        link2="http://abhinnkrishn.netlify.app/"
      />
    </div>
  );
};

export default Project;
