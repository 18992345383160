import React from "react";
import { Link } from "react-router-dom";
import "../css/heading.css";
import "../css/button.css";
import Programmer from "../images/html-css.svg";
import Mobile from "../images/mobile-touch.svg";

const Heading = () => {
  return (
    <div className="container-xxl pb-5 div-divider">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h1 className="name-size">
            Hi! I'm
            <span style={{ color: "#AE45FF" }}> ABHINN KRISHN</span>, your
            Programing Friend. I would{" "}
            <span style={{ color: "#B4161B" }}>❤</span> to help you out.
          </h1>
          <h5 className="text-muted text-center my-3">
            My Craft is to bring life in the code.
          </h5>
          <button className="btn btn-lg mx-auto my-2 d-flex contact-btn fifth">
            <a href="mailto:abhinnk21@gmail.com">Contact Me</a>
          </button>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <img src={Programmer} alt="Programmer" className="programmer-svg" />
          <img src={Mobile} alt="Mobile" className="mobile-svg" />
        </div>
      </div>
    </div>
  );
};

export default Heading;
