import React from "react";

const About = () => {
  return (
    <div className="container-xxl pb-5 div-divider">
      <div className="jumbotron py-5">
        <h1 className="">About Me</h1>
      </div>
      <div className="mx-auto d-flex about-width">
        <h5
          style={{ lineHeight: "30px" }}
          className="text-center description mb-3"
        >
          I'm a JavaScript Developer building ReactJS websites, looking for
          opportunities in the Front-end Web Development Industry. I code in
          most of the popular JavaScript Frameworks and Libraries, my goal is to
          provide the best experience and User satisfaction.
          <br />
          <br />
          I'm a freelance Developer from Lucknow, India, with an experience of
          over Two years in Design. I expertise in User Interface and User
          Experience and Search Engine Optimization.
        </h5>
      </div>
    </div>
  );
};

export default About;
