import React from "react";

const Footer = () => {
  return (
    <div className="jumbotron py-5">
      <div className="text-center">
        <h6 className="">
          This Website is created and maintained by Abhinn Krishn.
        </h6>
        <p>
          <span className="text-muted">&copy;</span>Abhinn Krishn{" "}
          <span className="text-muted">(2020-21)</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
