import React from "react";
import TechCard from "./Cards/TechCard";
import Express from "../images/express.svg";
import Node from "../images/node-dot-js.svg";
import ReactJS from "../images/react.svg";
import JavaScript from "../images/javascript.svg";
import Java from "../images/java.svg";
import C from "../images/c.svg";
import MongoDB from "../images/mongodb.svg";

const TechStack = () => {
  return (
    <div className="container-xxl div-divider">
      <div className="my-5 d-flex row">
        <div className="jumbotron py-3">
          <h1>My Tech Stack</h1>
        </div>
        <TechCard src={MongoDB} alt="MongoDB" />
        <TechCard src={Express} alt="Express" />
        <TechCard src={ReactJS} alt="ReactJS" />
        <TechCard src={Node} alt="Node" />
        <TechCard src={JavaScript} alt="JavaScript" />
        <TechCard src={Java} alt="Java" />
        <TechCard src={C} alt="C/C++" />
      </div>
    </div>
  );
};

export default TechStack;
